import {
  List,
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { PanelProps } from "../../../types/props/PanelProps";
import ControlsPanel from "./ControlsPanel";
import { StructuresContext } from "../../../context/StructuresContext";
import { useContext } from "react";

import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function LegendPanel(props: PanelProps) {
  const { data: structuresData } = useContext(StructuresContext);

  return (
    <ControlsPanel header="Légende" {...props}>
      <List>
        {structuresData.types.map((structureType, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar
                style={{
                  background: "white",
                  boxShadow: "4.2px 4.2px 11.2px rgba(0,0,0,.15)",
                }}
              >
                <LocationOnIcon style={{ color: structureType.color }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={structureType.name} />
          </ListItem>
        ))}
      </List>
    </ControlsPanel>
  );
}
