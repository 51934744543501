import * as colors from "@material-ui/core/colors";

const EXCLUDED_COLORS = ["common", "blueGrey"];
const colorPalette = Object.keys(colors).filter(
  (palette) => !EXCLUDED_COLORS.includes(palette)
);

const typeColors = colors as { [palette: string]: { [shade: string]: string } };

const types = [
  "CCI, CMA, CA (Chambres Consulaires)",
  "CIBC (Centre Interinstitutionnel de Bilan de Compétences)",
  "Structures labellisées Occitanie Orientation",
  "Conseil départemental",
  "Espace Conseil VAE",
  "Transitions Pro",
  "Cap Emploi",
  "La Région près de chez vous",
  "Mission Locale",
  "Pôle emploi",
  "CIDFF (Centre d'Information sur les Droits des Femmes et des Familles)",
  "CIO (Centre d'Information et d'Orientation)",
  "APEC (Association pour l’Emploi des Cadres)",
  "SCUIO-IP (Service Commun Universitaire d'Information, d'Orientation et d'Insertion Professionnelle)",
  "Autres structures d’information",
];

export const structureTypes = types.map((type, index) => ({
  name: type,
  color: typeColors[colorPalette[index]][700],
}));
