import { StructureFilters } from "../types/Filters";
import { StructureMarker } from "../types/Structure";

const NEGATIVE = "Non";

export const filterStructureMarkers = (
  markers: StructureMarker[],
  structureFilters: StructureFilters
) => {
  return markers.filter((marker) => {
    const {
      type,
      cep_recherche_emploi,
      cep_salaries_independants,
      prescripteur,
    } = structureFilters.establishments;

    const { selectedProfiles } = structureFilters;

    if (type !== "" && type !== marker.type.name) {
      return false;
    }

    if (
      cep_recherche_emploi &&
      marker["CEP en recherche d’emploi"] === NEGATIVE
    ) {
      return false;
    }

    if (
      cep_salaries_independants &&
      marker["CEP salariés et indépendants"] === NEGATIVE
    ) {
      return false;
    }

    if (prescripteur && marker.Prescripteur === NEGATIVE) {
      return false;
    }

    // Filter on profiles
    if (
      selectedProfiles.length > 0 &&
      !marker.profiles.find((profile) => selectedProfiles.includes(profile))
    ) {
      return false;
    }

    return true;
  });
};
