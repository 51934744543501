import { Popup } from "react-leaflet";
import { StructureMarker } from "../../types/Structure";
import { useMarkerStyles } from "../../styles/useMarkerStyles";
import { Box, Typography, IconButton, Paper, Link } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useRef } from "react";

export default function MarkerPopup({ marker }: { marker: StructureMarker }) {
  const classes = useMarkerStyles();

  const popupRef = useRef<L.Popup>(null);

  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  return (
    <Popup closeButton={false} className={classes.popup} ref={popupRef}>
      <Box className={classes.panel}>
        <Box className={classes.header}>
          <Paper className={classes.typeIcon} elevation={2}>
            <LocationOnIcon style={{ color: marker.type.color }} />
          </Paper>
          <Typography variant="h6" className={classes.title}>
            {marker.Nom}
          </Typography>
          <IconButton
            color="primary"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <Typography color="primary" variant="subtitle1">
            Type d'accueil
          </Typography>
          <Typography variant="subtitle2">
            {marker["Type d’accueil"]}
          </Typography>
          <Typography color="primary" variant="subtitle1">
            Type de structure
          </Typography>
          <Typography variant="subtitle2">
            {marker["Type de structure"]}
          </Typography>
          <Typography color="primary" variant="subtitle1">
            Structure
          </Typography>
          <Typography variant="subtitle2">{marker.Structure}</Typography>
          <Typography color="primary" variant="subtitle1">
            Adresse
          </Typography>
          <Typography variant="subtitle2">{marker.Adresse}</Typography>
          <Typography color="primary" variant="subtitle1">
            Code postal
          </Typography>
          <Typography variant="subtitle2">{marker["Code postal"]}</Typography>
          <Typography color="primary" variant="subtitle1">
            Commune
          </Typography>
          <Typography variant="subtitle2">{marker.Commune}</Typography>
          <Typography color="primary" variant="subtitle1">
            Département
          </Typography>
          <Typography variant="subtitle2">{marker.Département}</Typography>
          <Typography color="primary" variant="subtitle1">
            Téléphone
          </Typography>
          <Typography variant="subtitle2">{marker.Téléphone}</Typography>
          <Typography color="primary" variant="subtitle1">
            Prescripteur
          </Typography>
          <Typography variant="subtitle2">{marker.Prescripteur}</Typography>
          <Typography color="primary" variant="subtitle1">
            Site Web
          </Typography>
          <Typography variant="subtitle2">
            <Link href={marker["Site web"]}>Plus d'infos</Link>
          </Typography>
          <Typography color="primary" variant="subtitle1">
            Portail Me Former en Région
          </Typography>
          <Typography variant="subtitle2">
            <Link href={marker["Portail Me Former en Région"]}>
              Plus d'infos
            </Link>
          </Typography>
          <Typography color="primary" variant="subtitle1">
            CEP en recherche d'emploi
          </Typography>
          <Typography variant="subtitle2">
            {marker["CEP en recherche d’emploi"]}
          </Typography>
          <Typography color="primary" variant="subtitle1">
            CEP salariés et indépendants
          </Typography>
          <Typography variant="subtitle2">
            {marker["CEP salariés et indépendants"]}
          </Typography>
        </Box>
      </Box>
    </Popup>
  );
}
