import { useMapEvents } from "react-leaflet";
import ControlsGroup from "./ControlsGroup";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ControlButton from "./ControlButton";

export default function PositionControls() {
  const map = useMapEvents({
    locationfound(e) {
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  const handleLocate = () => {
    map.locate();
  };

  return (
    <ControlsGroup>
      <ControlButton Icon={AddIcon} onClick={() => handleZoomIn()} />
      <ControlButton Icon={RemoveIcon} onClick={() => handleZoomOut()} />
      <ControlButton Icon={LocationOnIcon} onClick={() => handleLocate()} />
    </ControlsGroup>
  );
}
