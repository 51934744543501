import L, { LatLngTuple, LeafletEvent } from "leaflet";
import { useContext, useEffect, useRef } from "react";
import { Marker, useMap, useMapEvents } from "react-leaflet";

import { StructuresContext } from "../../context/StructuresContext";

import { useMarkerStyles } from "../../styles/useMarkerStyles";
import MarkerPopup from "./MarkerPopup";
import MarkerClusterGroup from "react-leaflet-cluster";

const CLUSTER_MARKERS_THRESHOLD = 14;

const positionsMarkersHover = [
  [13, -21],
  [33.5, -10.5],
  [42, 12],
  [33.5, 35.5],
  [13, 42],
  [-9, 35.5],
  [-16, 12],
  [-9, -10.5],
] as LatLngTuple[];

export default function StructureMarkers() {
  const { markers } = useContext(StructuresContext);

  const markerClusterRef = useRef<L.MarkerClusterGroup>(null);

  let hoveredClusterId: string | null = null;
  const hoverMarkers: any[] = [];

  useMapEvents({
    zoom: () => {
      deleteMarkersHover();
    },
    movestart: () => {
      deleteMarkersHover();
    },
  });

  const map = useMap();

  const classes = useMarkerStyles();

  const deleteMarkersHover = () => {
    for (var e = hoverMarkers.length - 1; 0 <= e; --e) {
      map.removeLayer(hoverMarkers[e]);
      hoverMarkers.splice(e, 1);
    }
    hoveredClusterId = null;
  };

  useEffect(() => {
    const handleClusterMouseOver = (e: LeafletEvent) => {
      const clusterId = e.sourceTarget._leaflet_id;

      if (hoveredClusterId !== clusterId) {
        deleteMarkersHover();
        if (e.sourceTarget._childCount > CLUSTER_MARKERS_THRESHOLD) {
          return;
        }

        const clusterMarkers = e.sourceTarget.getAllChildMarkers().slice(0, 8);

        clusterMarkers.map((marker: any, i: number) => {
          const icon = marker.options.icon;
          icon.options.iconAnchor = positionsMarkersHover[i];
          icon.options.className = classes.hoverIcon;

          const hoverMarker = L.marker(
            [e.sourceTarget.getLatLng().lat, e.sourceTarget.getLatLng().lng],
            {
              icon,
            }
          ).bindPopup(marker._popup);

          hoverMarker.addTo(map);

          hoverMarkers.push(hoverMarker);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          hoveredClusterId = clusterId;

          return hoverMarker;
        });
      }
    };

    const markerClusterGroup = markerClusterRef.current as L.MarkerClusterGroup;

    if (markerClusterGroup) {
      markerClusterGroup.on("clustermouseover", handleClusterMouseOver);
    }

    // Clean up event listener when component unmounts
    return () => {
      if (markerClusterGroup) {
        markerClusterGroup.off("clustermouseover", handleClusterMouseOver);
      }
      // Delete hover markers on re-render
      deleteMarkersHover();
    };
  }, [markers]);

  return (
    <MarkerClusterGroup chunkedLoading ref={markerClusterRef}>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.coordinates} icon={marker.icon}>
          <MarkerPopup marker={marker} />
        </Marker>
      ))}
    </MarkerClusterGroup>
  );
}
