import { makeStyles } from "@material-ui/core/styles";

export const usePanelStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  panel: {
    padding: "20px",
    width: "446px",
  },
  paper: {
    marginLeft: "16px",
  },
  accordionSection: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  dropdown: {},
  section: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  expandIcon: {
    marginRight: "6px",
  },
  label: {
    marginBottom: "6px",
  },
  checkboxes: {
    "& span": {
      fontSize: "14px",
    },
  },
  content: {
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "8px",
  },
  centered: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-evenly",
  },
  mapIcon: {
    marginRight: "8px",
  },
});
