import { MapContainer, TileLayer } from "react-leaflet";
import { InteractiveMapProps } from "../types/props/InteractiveMapProps";
import RegionBorders from "./RegionBorders";
import CustomControlBar from "./controls/CustomControlBar";
import ExportControls from "./controls/ExportControls";
import FilterControls from "./controls/FilterControls";
import PositionControls from "./controls/PositionControls";
import StructureMarkers from "./markers/StructureMarkers";

export default function InteractiveMap({
  center,
  defaultZoom,
  borderPolygons,
}: InteractiveMapProps) {
  return (
    <MapContainer
      center={center}
      zoom={defaultZoom}
      zoomControl={false}
      scrollWheelZoom={true}
      style={{ height: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {/* Render the region borders */}
      {borderPolygons && <RegionBorders borderPolygons={borderPolygons} />}

      {/* Render the controls menu */}
      <CustomControlBar position="topleft">
        <PositionControls />
        <FilterControls />
        <ExportControls />
      </CustomControlBar>

      {/* Render the markers */}
      <StructureMarkers />
    </MapContainer>
  );
}
