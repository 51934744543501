import { GeoJSON } from "react-leaflet";
import { RegionBorderProps } from "../types/props/RegionBordersProps";

export default function RegionBorders({ borderPolygons }: RegionBorderProps) {
  return (
    <GeoJSON
      data={borderPolygons}
      style={{ fillOpacity: 0, color: "#6E6E6E", opacity: 1 }}
    />
  );
}
