import ControlsGroup from "./ControlsGroup";

import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ControlButton from "./ControlButton";
import { useRef, useState } from "react";
import ExportPanel from "./panels/ExportPanel";

export default function ExportControls() {
  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLButtonElement>();

  const panelRef = useRef<HTMLButtonElement>();

  const handleExport = () => {
    setPanelAnchorEl(panelRef.current);
  };

  return (
    <>
      <ControlsGroup panelRef={panelRef}>
        <ControlButton Icon={SystemUpdateAltIcon} onClick={handleExport} />
      </ControlsGroup>
      <ExportPanel
        open={panelAnchorEl !== null}
        anchorEl={panelAnchorEl}
        onClose={() => setPanelAnchorEl(undefined)}
      />
    </>
  );
}
