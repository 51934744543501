import React, { createContext, useState } from "react";
import {
  Structure,
  StructureMarker,
  StructuresContextData,
} from "../types/Structure";
import { divIcon } from "leaflet";
import { LatLngTuple } from "leaflet";
import { renderToString } from "react-dom/server";
import { useMarkerStyles } from "../styles/useMarkerStyles";
import { groupBy } from "lodash";
import { profiles } from "../data/structures/profiles";
import { structureTypes } from "../data/structures/types";

import LocationOnIcon from "@material-ui/icons/LocationOn";

const defaultStructuresData = {
  markers: [],
  types: [],
  profiles: [],
};

// Create the context
const StructuresContext = createContext<StructuresContextData>({
  data: defaultStructuresData,
  updateMarkers: () => {},
  markers: [],
});

const StructuresProvider = ({
  children,
  structures,
}: {
  children: React.ReactNode;
  structures: Structure[];
}) => {
  const classes = useMarkerStyles();

  // Group structures by coordinates and name
  const groupedStructures = groupBy(
    structures,
    (structure) =>
      structure.Latitude + "," + structure.Longitude + ";" + structure.Nom
  );

  // Build the markers
  const structureMarkers = Object.keys(groupedStructures).map((identifier) => {
    const identifiedStructures = groupedStructures[identifier];
    const refStructure = identifiedStructures[0];

    const [formattedCoordinates] = identifier.split(";");
    const [lat, lng] = formattedCoordinates.split(",");
    const coordinates = [Number(lat), Number(lng)] as LatLngTuple;

    const type = structureTypes.find(
      (structureType) =>
        structureType.name === refStructure["Type de structure"]
    )!;

    const icon = divIcon({
      html: renderToString(
        <LocationOnIcon
          fontSize="large"
          style={{ color: type.color }}
          className={classes.marker}
        />
      ),
      className: classes.icon,
    });

    return {
      ...refStructure,
      coordinates,
      profiles: identifiedStructures.map((structure) => structure.Profil),
      icon,
      type,
    };
  });

  const structuresContextData = {
    markers: structureMarkers,
    types: structureTypes,
    profiles: profiles,
  };

  const [markers, setMarkers] = useState<StructureMarker[]>(structureMarkers);

  // Expose a markers update for filtering
  const updateMarkers = (markers: StructureMarker[]) => {
    setMarkers(markers);
  };

  return (
    <StructuresContext.Provider
      value={{
        data: structuresContextData,
        updateMarkers,
        markers,
      }}
    >
      {children}
    </StructuresContext.Provider>
  );
};

export { StructuresContext, StructuresProvider };
