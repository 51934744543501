import { Box, Paper } from "@material-ui/core";
import { ControlGroupProps } from "../../types/props/ControlGroupProps";

export default function ControlsGroup({
  children,
  panelRef,
}: ControlGroupProps) {
  return (
    <Paper ref={panelRef}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 4,
          padding: 5,
        }}
      >
        {children}
      </Box>
    </Paper>
  );
}
