import { makeStyles } from "@material-ui/core/styles";

export const useMarkerStyles = makeStyles({
  icon: {
    background: "transparent",
  },
  marker: {
    marginLeft: "-12px",
    marginTop: "-35px",
  },
  hoverIcon: {
    background: "#fff",
    borderRadius: "50%",
    width: "23px !important",
    height: "23px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > svg": {
      margin: "0 !important",
      fontSize: "1.5rem",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  panel: {
    padding: "4px",
  },
  content: {
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "8px",
  },
  popup: {
    width: "446px",
    "& .leaflet-popup-content": {
      width: "406px !important",
    },
  },
  typeIcon: {
    borderRadius: "50%",
    display: "flex",
    marginRight: "12px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  close: {
    marginLeft: "auto",
  },
});
