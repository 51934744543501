import { PanelProps } from "../../../types/props/PanelProps";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ControlsPanel from "./ControlsPanel";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { throttle } from "lodash";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import { usePanelStyles } from "../../../styles/usePanelStyles";
import { useMap } from "react-leaflet";

const SEARCH_ZOOM = 15;

const autocompleteService = { current: null } as {
  current: google.maps.places.AutocompleteService | null;
};

const geocoder = new google.maps.Geocoder();

const highlightMatchedParts = (text: string, matches: number[][]) => {
  let startIndex = 0;
  let endIndex = 0;
  const parts = [];

  matches.forEach((match) => {
    endIndex = match[0];
    parts.push({
      text: text.substring(startIndex, endIndex),
      highlight: false,
    });
    startIndex = match[0] + match[1];
    const matchedPart = text.substring(endIndex, startIndex);
    parts.push({
      text: matchedPart,
      highlight: true,
    });
  });

  parts.push({
    text: text.substring(startIndex),
    highlight: false,
  });

  return parts;
};

export default function SearchPanel(props: PanelProps) {
  const classes = usePanelStyles();

  const map = useMap();

  const [value, setValue] =
    useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (
            results?: google.maps.places.AutocompletePrediction[]
          ) => void
        ) => {
          autocompleteService.current!.getPlacePredictions(
            { ...request, componentRestrictions: { country: "fr" } },
            callback
          );
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue },
      (results?: google.maps.places.AutocompletePrediction[]) => {
        if (active) {
          let newOptions = [] as google.maps.places.AutocompletePrediction[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <ControlsPanel header="Rechercher une structure" {...props}>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        options={options}
        autoComplete
        value={value}
        onChange={(
          event: any,
          newValue: google.maps.places.AutocompletePrediction | null
        ) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);

          // Get details about the selected location
          geocoder.geocode(
            { placeId: newValue?.place_id },
            (results, status) => {
              if (
                status === google.maps.GeocoderStatus.OK &&
                results.length > 0
              ) {
                const location = results[0].geometry.location;
                const lat = location.lat();
                const lng = location.lng();

                // Move to the position
                map.flyTo([lat, lng], SEARCH_ZOOM, { animate: true });
              }
            }
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Saisissez votre adresse"
            margin="normal"
            color="primary"
            variant="outlined"
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = highlightMatchedParts(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.mapIcon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </ControlsPanel>
  );
}
