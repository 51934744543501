import React from "react";
import InteractiveMap from "./components/InteractiveMap";
import { LatLngTuple } from "leaflet";
import occitanieBorders from "./data/region-occitanie.geo.json";
import { RegionGeoType } from "./types/RegionGeoType";
import { Container, ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";

import structures from "./data/data.json";
import { Structure } from "./types/Structure";
import { StructuresProvider } from "./context/StructuresContext";

const DEFAULT_CENTER = [43.691758790967675, 2.2163776278037965] as LatLngTuple;
const DEFAULT_ZOOM = 8;
const REGION_BORDERS = occitanieBorders as RegionGeoType;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" style={{ height: "80%", margin: "auto" }}>
        <StructuresProvider structures={structures as Structure[]}>
          <InteractiveMap
            center={DEFAULT_CENTER}
            defaultZoom={DEFAULT_ZOOM}
            borderPolygons={REGION_BORDERS}
          />
        </StructuresProvider>
      </Container>
    </ThemeProvider>
  );
}

export default App;
