import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
  ListItem,
} from "@material-ui/core";
import { PanelProps } from "../../../types/props/PanelProps";
import ControlsPanel from "./ControlsPanel";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { usePanelStyles } from "../../../styles/usePanelStyles";
import { ReactNode, useContext, useState } from "react";
import { StructuresContext } from "../../../context/StructuresContext";
import { StructureFilters } from "../../../types/Filters";
import { filterStructureMarkers } from "../../../services/filters";

function isFiltered(structureFilters: StructureFilters["establishments"]) {
  return Object.values(structureFilters).filter((filter) => filter).length > 0;
}

export default function FilterPanel(props: PanelProps) {
  const classes = usePanelStyles();
  const { data: structuresData, updateMarkers } = useContext(StructuresContext);

  const [structureFilters, setStructureFilters] = useState<StructureFilters>({
    establishments: {
      type: "",
      cep_recherche_emploi: false,
      cep_salaries_independants: false,
      prescripteur: false,
    },
    selectedProfiles: [],
  });

  const { establishments, selectedProfiles } = structureFilters;

  const handleSelectStructureType = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    filterStructures({
      ...structureFilters,
      establishments: { ...establishments, type: e.target.value as string },
    });
  };

  const handleToggleProfile = (profile: string, checked: boolean) => {
    const filteredProfiles = checked
      ? [...selectedProfiles, profile]
      : [
          ...selectedProfiles.filter(
            (selectedProfile) => selectedProfile !== profile
          ),
        ];

    filterStructures({ establishments, selectedProfiles: filteredProfiles });
  };

  const filterStructures = (filters: StructureFilters) => {
    // Update current applied filters
    setStructureFilters(filters);

    // Perform the filtering on the context data
    const filteredMarkers = filterStructureMarkers(
      structuresData.markers,
      filters
    );
    updateMarkers(filteredMarkers);
  };

  return (
    <ControlsPanel header="Filtrer ma recherche" {...props}>
      <AccordionSection
        title="Établissements"
        active={isFiltered(establishments)}
      >
        <>
          <Typography variant="h6" className={classes.label}>
            Type de structure
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Select
              value={establishments.type}
              onChange={handleSelectStructureType}
              displayEmpty
            >
              <MenuItem value="">Sélectionner une structure</MenuItem>
              {structuresData.types.map((structureType, index) => (
                <MenuItem value={structureType.name} key={index}>
                  {structureType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormGroup className={classes.checkboxes}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={establishments.cep_recherche_emploi}
                  onChange={(e, checked) =>
                    filterStructures({
                      ...structureFilters,
                      establishments: {
                        ...establishments,
                        cep_recherche_emploi: checked,
                      },
                    })
                  }
                />
              }
              label="Réseau habilité “CEP en recherche d’emploi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={establishments.cep_salaries_independants}
                  onChange={(e, checked) =>
                    filterStructures({
                      ...structureFilters,
                      establishments: {
                        ...establishments,
                        cep_salaries_independants: checked,
                      },
                    })
                  }
                />
              }
              label="Réseau habilité “CEP salariés et indépendants"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={establishments.prescripteur}
                  onChange={(e, checked) =>
                    filterStructures({
                      ...structureFilters,
                      establishments: {
                        ...establishments,
                        prescripteur: checked,
                      },
                    })
                  }
                />
              }
              label="Prescripteur pour les formations financées par la région"
            />
          </FormGroup>
        </>
      </AccordionSection>
      <AccordionSection title="Mon profil" active={selectedProfiles.length > 0}>
        <List>
          {structuresData.profiles.map((profile, index) => (
            <ListItem key={index}>
              <ListItemText primary={profile} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={(e, checked) =>
                    handleToggleProfile(profile, checked)
                  }
                  checked={selectedProfiles.includes(profile)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </AccordionSection>
    </ControlsPanel>
  );
}

function AccordionSection({
  title,
  children,
  active,
}: {
  title: string;
  children: ReactNode;
  active: boolean;
}) {
  const classes = usePanelStyles();

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Box
        className={classes.accordionSection}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <ExpandLessIcon className={classes.expandIcon} color="primary" />
        ) : (
          <ExpandMoreIcon className={classes.expandIcon} color="primary" />
        )}
        <Box className={classes.section}>
          <Typography color="primary">{title}</Typography>
          <Switch checked={active} />
        </Box>
      </Box>
      <Collapse in={expanded} className={classes.dropdown}>
        {children}
      </Collapse>
    </>
  );
}
