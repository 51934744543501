import { useRef, useState } from "react";
import { FilterControlPanels } from "../../types/FilterControlPanels";
import SearchPanel from "./panels/SearchPanel";
import ControlsGroup from "./ControlsGroup";

import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import InfoIcon from "@material-ui/icons/Info";
import ControlButton from "./ControlButton";
import FilterPanel from "./panels/FilterPanel";
import LegendPanel from "./panels/LegendPanel";

export default function FilterControls() {
  const [displayedPanel, setDisplayedPanel] =
    useState<FilterControlPanels | null>(null);

  const panelRef = useRef<HTMLButtonElement>();

  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLButtonElement>();

  const handleTogglePanel = (
    event: React.MouseEvent<HTMLButtonElement>,
    panel: FilterControlPanels
  ) => {
    setPanelAnchorEl(panelRef.current);
    if (displayedPanel === panel) {
      setDisplayedPanel(null);
    } else {
      setDisplayedPanel(panel);
    }
  };

  const handleClosePanel = () => {
    setDisplayedPanel(null);
  };

  return (
    <>
      <ControlsGroup panelRef={panelRef}>
        <ControlButton
          Icon={SearchIcon}
          active={displayedPanel === FilterControlPanels.SEARCH}
          onClick={(e) => handleTogglePanel(e, FilterControlPanels.SEARCH)}
        />
        <ControlButton
          Icon={TuneIcon}
          active={displayedPanel === FilterControlPanels.FILTER}
          onClick={(e) => handleTogglePanel(e, FilterControlPanels.FILTER)}
        />
        <ControlButton
          Icon={InfoIcon}
          active={displayedPanel === FilterControlPanels.LEGEND}
          onClick={(e) => handleTogglePanel(e, FilterControlPanels.LEGEND)}
        />
      </ControlsGroup>
      <SearchPanel
        open={displayedPanel === FilterControlPanels.SEARCH}
        anchorEl={panelAnchorEl}
        onClose={handleClosePanel}
      />
      <FilterPanel
        open={displayedPanel === FilterControlPanels.FILTER}
        anchorEl={panelAnchorEl}
        onClose={handleClosePanel}
      />
      <LegendPanel
        open={displayedPanel === FilterControlPanels.LEGEND}
        anchorEl={panelAnchorEl}
        onClose={handleClosePanel}
      />
    </>
  );
}
