import { makeStyles } from "@material-ui/core/styles";

export const useControlStyles = makeStyles({
  button: {
    backgroundColor: "#fff",
    width: "40px",
    height: "40px",
    minWidth: "40px",
    boxShadow: "none",
    marginBottom: 2,
    "&.active": {
      background: "#efccd4",
    },
  },
});
