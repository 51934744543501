import { Box, IconButton, Popover, Typography } from "@material-ui/core";
import { usePanelStyles } from "../../../styles/usePanelStyles";

import CloseIcon from "@material-ui/icons/Close";
import { ControlsPanelProps } from "../../../types/props/ControlsPanelProps";

export default function ControlsPanel({
  onClose,
  open,
  anchorEl,
  header,
  children,
}: ControlsPanelProps) {
  const classes = usePanelStyles();

  return (
    <>
      {anchorEl && (
        <Popover
          onClose={onClose}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          marginThreshold={40}
          classes={{ paper: classes.paper }}
        >
          <Box className={classes.panel}>
            <Box className={classes.header}>
              <Typography variant="h6">{header}</Typography>
              <IconButton color="primary" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.content}>{children}</Box>
          </Box>
        </Popover>
      )}
    </>
  );
}
