import { Button } from "@material-ui/core";
import { useControlStyles } from "../../styles/useControlStyles";
import { ControlButtonProps } from "../../types/props/ControlButtonProps";

export default function ControlButton({
  Icon,
  active,
  onClick,
}: ControlButtonProps) {
  const classes = useControlStyles();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      className={`${classes.button} ${active === true ? "active" : ""}`}
    >
      <Icon color="primary" />
    </Button>
  );
}
