import { Box, Button, Typography } from "@material-ui/core";
import { PanelProps } from "../../../types/props/PanelProps";
import ControlsPanel from "./ControlsPanel";
import { usePanelStyles } from "../../../styles/usePanelStyles";

export default function ExportPanel(props: PanelProps) {
  const classes = usePanelStyles();

  const exportData = () => {
    const downloadUrl = "/data/export.xlsx";
    window.open(downloadUrl, "_blank");
  };

  return (
    <ControlsPanel header="Exportation des données" {...props}>
      <Box className={classes.centered}>
        <Typography variant="h6" color="primary" gutterBottom>
          Voulez-vous exporter les données ?
        </Typography>
        <Typography variant="caption" gutterBottom>
          exportation au format .xls
        </Typography>
        <Box className={classes.actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => props.onClose()}
          >
            Non, j'annule
          </Button>
          <Button variant="contained" color="primary" onClick={exportData}>
            Oui, je confirme
          </Button>
        </Box>
      </Box>
    </ControlsPanel>
  );
}
